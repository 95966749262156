
  /* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap'); */
  /* @import url('https://fonts.cdnfonts.com/css/source-sans-pro'); */

/* body{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
} */

.error{
  color: rgb(221 6 6);
  font-weight: bold;
  text-align: left;
}

.requiredField{
  color: rgb(221 6 6);
  font-weight: bold;
}

.modal-content {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  }

.mobileMenuModal .modal-dialog{
  width: 50% !important;
}

.cadMobileMenu{
  padding: 0;
    margin: 0;
    list-style-type: none;
}

.cadMobileMenu li{
    color: #000000;
    margin: 16px;
}
.cadMobileMenu li a{
    color: #1a2c47;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
}

.cadSMobileMenu{
  display: none;
}

.cadMobileMenu li:hover>.cadSMobileMenu
{
  display: block;
}

.cadUserRow{
  border-bottom: 1px solid #d0d1db;
    padding-bottom: 10px;
    font-weight: bold;
}

.cursor{
  cursor: pointer;
}

.cadAllLearning{
  margin-top: 10px;
  font-weight: 600;
  background-color: #0D4969;
  cursor: pointer;
  color: #FFF;
  margin-right: 10px;
    margin-left: 10px;
}

.cadAllLearning a{
  padding:10px !important;
}



.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}

.navigation span{
  color: #FFF;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin:0;
  list-style-type: none;
}

.navigation-menu ul li .profileMenu li a{
  color: #FFF;
  text-decoration: none;
  display: block;
  width: 100%;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 15px !important;
  margin-right: 10px;
  transition: all 0.5s;
  font-family: 'Source Sans Pro', sans-serif;
  /* font-family: 'Source Sans 3', sans-serif;
}

.navigation-menu ul li{
  /* display: inline-flex */
}

/* .navigation-menu li {
 
  list-style-type: none;
  margin: 0 1rem;
  line-height: 40px;
} */
.navigation-menu ul li a {
  color:#FFF;
  text-decoration: none;
  display: block;
  width: 100%;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 20px 25px;
  margin-right: 10px;
  transition: all 0.5s;
  font-family: 'Source Sans Pro', sans-serif;
  /* font-family: 'Source Sans 3', sans-serif; */
}

.navigation-menu ul li.sub-menu>a:after{
  content: '\2193';
  font-family: revert-layer;
  position: absolute;
  color:#FFF;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.navigation-menu ul li.sub-menu:hover > a:after{

}

.navigation-menu ul .dropdown-menu{
  color:#FF0000;
  width:185px;
  position: absolute;
  top:45px;
  line-height: 5px;
  border-top: 4px solid #ff652f;
  display: block !important;
  opacity: 0;
  visibility: hidden;
}

.navigation-menu ul .dropdown-menu li{
    width:100%;
}

.navigation-menu ul .dropdown-menu li a{
  color:#FF0000;
  display: block;
}

.navigation-menu ul li:hover .dropdown-menu{
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}

.navigation-menu .mega-menu{
  position: absolute;
  top: 45px;
  width: 100%;
  left: 0;
  z-index: 1;
  /* opacity: 0;
  visibility: hidden; */
  display: none;
  transition: all 0.5s;
  box-shadow: 0 3px 10px 0 rgb(0 32 119 / 6%) !important;
}

.navigation-menu .profile-menu{
  position: absolute;
  top: 43px;
  /* width: 100%; */
  right: 0;
  z-index: 1;
  /* opacity: 0;
  visibility: hidden; */
  display: none;
  transition: all 0.2s;
  box-shadow: 0 3px 10px 0 rgb(0 32 119 / 6%) !important;
}

.navigation-menu .learning-menus{
  position: absolute;
  top: 43px;
  /* width: 100%; */
  padding-bottom: 10px;
    background: white;
  z-index: 1;
  /* opacity: 0;
  visibility: hidden; */
  display: none;
  transition: all 0.2s;
  box-shadow: 0 3px 10px 0 rgb(0 32 119 / 6%) !important;
}

.navigation-menu ul li:hover .learning-menus{
  /* opacity: 1;
  visibility: visible; */
  display: block;
  transition: all 0.2s;
}

.navigation-menu ul li:hover .profile-menu{
  /* opacity: 1;
  visibility: visible; */
  display: block;
  transition: all 0.2s;
}

.navigation-menu .profile-menu .content{
  background-color:#ffffff;
  /* padding:25px 20px; */
  border-top:4px solid #b8b8b8;
  width:100%;

}

.navigation-menu ul li:hover .mega-menu{
  /* opacity: 1;
  visibility: visible; */
  display: block;
  transition: all 0.5s;
}

.navigation-menu .mega-menu .content{
  background-color:#ffffff;
  padding:25px 20px;
  border-top:4px solid #b8b8b8;
  width:100%;

}

.navigation-menu .mega-menu .content .rows{
    line-height: 45px;
}

.navigation-menu .mega-menu .content .rows .title{
  color: #0D4969;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid;
    width: 80%;
    text-align: left;
    padding-bottom: 5px;
    font-weight: bold;
}

.navigation-menu .mega-menu .content .rows .mega-menu-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}

.navigation-menu .mega-menu .content .rows .mega-menu-links li{
  display:block;
  padding:0 20px;
  text-align: left;
}

.navigation-menu .mega-menu .content .rows .mega-menu-links li a{
  padding: 0 20px;
    color: #0D4969;
    font-size: 13px;
    display: block;
    line-height: 25px;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #2642af;
}

.MuiOutlinedInput-notchedOutline{
  border:none !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
  border:none !important;
}

@media only screen and (max-width: 768px) 
{

  .qtShareContacts {
    text-align: center !important;
}

.qtCourseButtons {
  text-align: center !important;
  margin-bottom: 10px;
}

.qtTrainingTime {
  padding: 2em !important;
  width: 100% !important;
}

.qtTimingBatch {
  width: 100% !important;
}

.qtDesContent ul li {
  width: auto !important;
  margin-top:10px !important;
}

.qtFAQContent {
 margin:0 !important;
}

.qtCourseOverlay {
  width: 95% !important;
}

.qtDesSalary{
  width: 100% !important;
}


  .cartImages{
    display: none;
  }

  .cadCartOption .fa-trash{
    display:none;
  }

  .cadCartDetails h2 {
    line-height: none;
    font-size: 15px !important;
}

.cadCartValue {
  font-size: 15px !important;
  line-height: 0 !important;
  text-align: right;
}

.cadCartOption ul li {
  font-size: 12px;
}

.cadCartItem {
  height:32px !important;
}

.cardCartValues{
  flex: 100px;
}

  .cadEmails{
    width:100% !important;
  }

  .navigation span {
    
    font-size: 12px;
}

.dLogin{
  display:none;
}

.login{
  float:left !important;
}

.MuiAutocomplete-root{
  width:100% !important;
}

.mobileLoginModal .modal-dialog{
  width:100% !important;
}

  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul {
   
    position: absolute;
    top: 60px;
    left: 0;
   
    flex-direction: column;
  
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li {
   
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
   
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .mainBanner{
    padding-left:0px !important;
  }

.qtBannerHead {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
}
.qtBannerHead,.qtBannerHeads{
    font-size: 25px !important;
    text-align: center;
}

.qtBannerSubHead{
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

.bannerSkew{
  transform: skewX(23deg);
}

.qtBannerSubHead h1{
  margin-top: 15px;
    font-weight: revert-layer;
}

.qtBannerLink{
  text-align: center !important;
  font-family: 'Source Sans Pro', sans-serif;
}



.mainBanner{
  height: auto !important;
}

.qtBannerTop{
  height: auto !important;
  vertical-align: top !important;
    padding-top: 10px;
    padding-bottom: 40px;
}

.qtBanRightText{
  -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    margin-right:0px !important;
}

.qtBanRight{
  width:100% !important;
}

.qtCountsMain {
  height: auto !important;
}

.qtBorderRight {
  border-right: 0px !important;
}

.qtBanBotContent {
  margin-top: 0px !important;
}

.qtTalkComponent{
  height:auto !important;
  padding-bottom: 20px;
}
.qtTalkContent{
  text-align: center !important;
}

.qtTalkLink {
  height: auto !important;
  vertical-align: top !important;
  display : block !important;
}

.qtMasterContent {
  text-align: center !important;
}

.qtMasterBtn {
 text-align:center !important;
}

.qtTrainer {
  height: auto !important;
}

.qtExpContents {
  text-align: right !important;
  font-size: 20px !important;
  line-height: 110px !important;
  padding-right: 10px !important;
  text-decoration: none;
}

.qtFooterHead h3 {
  color: #DBFFFF;
  text-align: center !important;
}
.qtFooterMain ul li {
 
  text-align: center !important;
}


.qtCountMain {
  height: 100px !important;
}

.topHeader .linksRight {
  margin-top:0px !important;
}

}

.wrapper{
  flex:1;
}

.footer{
  margin-top: auto;
}

.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headers{
  position: sticky;
    top: 0;
    z-index: 1;
}

div.trueHeader,nav.trueHeader{
  width: 100%;
    height: 50px;
    background: #0D4969;
    font-family: 'Source Sans Pro', sans-serif;
    
}

.headers.topHeader{
  background: #FFF;
 
}

.trueHeader .linksRight{
  float: right;
  line-height: 48px;
}

.trueHeader .linksLeft{
  float: left;
  line-height: 48px;
}

.trueHeader .linksRight ul li{
  display: inline-block;
  margin-left: 15px;
}

.trueHeader .linksRight ul li a{
  text-decoration: none;
  color: #FFF;
}

.trueHeader .linksLeft ul li{
  display: inline-block;
  color: #FFF;
  margin-right: 10px;
}

.topHeader{
  width: 100%;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  line-height: 40px;
  box-shadow: 0 3px 10px 0 rgb(0 32 119 / 6%) !important;
  background: #FFF;
}

.topHeader input{
  width: 100%;
    margin-top: 20px;
    border: #0D4969 1px solid;
    border-radius: 20px;
    padding-left: 10px;
}

.qtBanBotContent{
  margin-top: 38px;
  font-family: 'Source Sans Pro', sans-serif;
}

.qtWhyChoose{
  font-family: 'Source Sans Pro', sans-serif;
}

.qtWhyChoose h4{
  font-weight: bold;
}

.qtBannerBottoms{
  /* margin: 0 auto;
    width: 75%;
    height: 200px;
    background-color: #fff;
    margin-top: -55px;
    box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    border-radius: 10px; */
    height: 150px;
    background-color: #0d4969;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.qtBanImg{
  color: #0d4969;;
  font-size: 60px;
  
    text-align: right;
    padding: 0;
}

.qtBanText{
  text-align: left;
    color: #0d4969;
    font-size: 25px;
    vertical-align: middle;
    padding: 0;
    line-height: 28px;
    padding-top: 18px;
}

.qtBorderRight{
  border-right: 2px solid #FFF;
}

.qtBanRight{
  width: 50%;
    height: 80px;
    background-color: #b7741a;
    margin-top: -25px;
}

.qtBanRightText{
  height: 80px;
    -webkit-transform: skew(338deg);
    -moz-transform: skew(338deg);
    -o-transform: skew(338deg);
    background: #b7741a;
    margin-right: -20px;
    line-height: 70px;
    color: #FFF;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtBanRightText span{
  font-size: 35px;
}

.login{
  width: 100px;
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid #0c9584;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #0c9584;
    float: right;
    position: relative;
    top: 3px;
    text-decoration: none;
}

.topHeader .linksRight{
    float:right;
    margin-top: 20px;
    display: inline-block;
}

.mainBanner{
  background: url('../src/qtreebanner.png');
  background-size: cover;
    height: 450px;
    padding-left: 6em;
}

.mainBanner img{
  width:100%;
}

.qtpgRow{
  width:100%;
}

.qtPrograms{
  -webkit-box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    width: 100%;
    margin-bottom: 2.4rem;
    padding-bottom: 1em;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtPrograms:hover{
  background: #dceaf7;
  /* cursor: pointer; */
}

.qtpgHeading{
  text-align: center;
  padding: 1em;
  /* font-size: 4rem; */
  margin: 4em;
  font-family: 'Source Sans Pro', sans-serif;
}

.qtpgHeading h1{
  font-weight: bold;
}

.qtpgHeading h2{
  font-weight: bold;
  font-size: 2.5rem;
}

.qtpgHeading h2 span{
  font-weight: normal;
}

.qtpgHeading h1 span{
  font-weight: normal;
}

.programsHeadCard1{
  background: linear-gradient(94.33deg, #0359af -26.26%, #0776dd 128.29%);
}
.programsHeadCardSk1
{
  /* background: linear-gradient(94.33deg, #c5cdd5 -26.26%, #f4f9ff 128.29%); */
}

.programsHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0.2rem 0.4rem 2.4rem rgb(0 0 0 / 4%);
  box-shadow: 0.2rem 0.4rem 2.4rem rgb(0 0 0 / 4%);
  border-radius: 0.8rem 0.8rem 0 0;
  height:100px;
}

.programsheadText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  text-align: left;
  align-items: center;
  font-weight: 600;
  /* font-size: 1.8rem; */
  color: #f2f8ff;
  padding-left: 16px;
    /* padding: 2rem 2.8rem 2.4rem 3.2rem;*/
}

.programsHeadImg{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 3.2rem;
}

.programs__body {
  margin: 3.2rem;
  color: #61738e;
}

.programs__experience {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.4rem;
}

.programs__experience-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: left;
  margin-left: 1rem;
}

.programs__pointer {
  margin-bottom: 1.6rem;
}

.programs__pointer-head {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.3rem;
  text-align: left;
  margin-bottom: 0.8rem;
  color: #1a2c47;
}

.programs__pointer-desc {
  text-align: left;
}

.programs__projects {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.8rem;
}

.programs__projects-text {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: left;
  margin-left: 0.7rem;
}

.programs__cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.programs__cta-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #0080ff;
}

.qtAlumni{
  width:100%;
  background-color: #FFF;
  -webkit-box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    height:auto;
    padding: 2em;
    border-radius: 15px;

}

.qtAlumni img{
  width:100%;
}

.clearfix{
  width:100%;
  height:1em;
}

.qtFooter{
  width:100%;
  background-color: #202738;
  margin-top: 2em;
  padding-top: 1em;
  /* padding-bottom: 1em; */
  margin-bottom: 14px;
  
  padding-top: 2em
}

.qtFooterHead h3{
  color: #FFFFFF;
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.qtFooterMain a{
  color: #a2b8ee;
    text-decoration: none;
    font-size: 14px;
}

.qtFooterMain ul{
  list-style-type: none;
  padding: 0;
  color: #a2b8ee;
  font-size: 14px;

}

.qtFooterMain ul li{
  color:#a2b8ee;
  text-align: left;
  padding-bottom: 0.5rem;
}

.qtMainHead{
  margin: 2em;
  text-align: left;
}

.qtMainHead h3{
  color: #0D4969;
}

.qtMainHead h5 {
  font-size: 1.5em;
  font-weight: 400;
}

.qtMainHead h1{
  font-weight: normal;
    font-size: 1.5em;
}

.qtSecHead h2{
  font-size: 1em;
    text-align: left;
}

.qtBootCamp{
  background: #e8f7ff54;
    padding-top: 2em;
    padding-bottom: 2em;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtBootCamp h4{
  font-weight: bold;
}

.qtSecHead h2{
  font-weight: bold;
}

.qtBootContent p{
  text-align: justify;
    font-size: 0.9em;
}

.qtMainContents{
  padding-top: 1em;
}

.qtBootIcon{
  text-align: left;
    font-size: 2em;
    color:#0C9584;
}

.qtExpContent{
  box-sizing: border-box;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.qtExpContent img{
  border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.qtExpContents{
  /* text-align: left;
    font-weight: bold;
    font-size: 1em;
    line-height: 50px; */
    text-align: left;
    font-weight: bold;
    /* font-size: 1em; */
    line-height: 50px;
    width: 100px;
}

.qtInfoBox{
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.qtInfoContent{
  border: 4px solid #0D4969;
    padding: 1em;
}

.qtInfoMainContent{
  text-align: left;
    font-weight: bold;
    line-height: 100px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;
}

.qtTrueFooter{
  width: 100%;
  background-color: rgb(64 64 60);
  color: #FFF;
  padding-top: 5px;
  padding-bottom: 5px;
}

.qtMasterMain{
  width: 100%;
    margin-top: 1em;
    margin-bottom: 4em;
    background: aliceblue;
    height: auto;
    overflow: auto;
    padding-bottom: 2.5em;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtTestimonials{
  font-family: 'Source Sans Pro', sans-serif;
}

.qtTestHeading h2{
  font-weight: bold;
}

.qtMasterContent{
  text-align: left;
    
    font-size: 2em;
    padding-top: 1em;
    padding-bottom: 0.5em;
}

.qtMasterContent span{
    color: #FF0000;
    font-weight: bold;
}

.qtMasterMainContents{
  text-align: left;
    padding-top: 1em;
    padding-bottom: 2em;
    font-size: 25px;
}

.qtMasterBtn{
  text-align: left;
}

.qtMasterBtn a
{
  text-decoration: none;
}

.qtMasterBtn span{
  padding-left: 25px;
    background: #363636;
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.programsLink{
  text-align: left;
  padding-top: 1em;
}

.programsLink a{
  text-decoration: none;
    font-size: 18px;
    font-weight: bold;
}

.qtExplore{
  margin-top: 3em;
  font-family: 'Source Sans Pro', sans-serif;
}

.qtExplore h4{
  font-weight: bold;
}

.qtExplain{
  margin-top: 2em;
}

.qtMasterImg{
  width:100%;
}

.qtMasterImg img{
  width:100%;
}

.qtBannerCont{
  /* height:450px; */
}

.qtBannerHeads{
  text-align: left;
    color: #FFF;
}

.qtBannerHead,.qtBannerHeads{
  font-size: 45px;
}

.qtTrueBanner{
  /* margin: 0;
    top: 50%;
    position: absolute; */
}

.qtBannerTop{
  height: 450px;
    display: table-cell;
    vertical-align: middle;
}

.qtBannerHead{
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
}

.qtBannerContent{
  text-align: left;
    color: #FFF;
    font-size: 18px;
    margin-top: 1em;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtBannerLink{
  text-align: left;
  margin-top: 2em;
}

.qtBannerLink a{
  text-decoration: none;
    color: #FFF;
    /* display: block; */
    padding: 10px;
    border: 1px solid;
    font-family: 'Source Sans Pro', sans-serif;
}

/*left right modal*/
.modal.left_modal, .modal.right_modal{
  position: fixed;
  z-index: 99999;
}
.modal.left_modal .modal-dialog,
.modal.right_modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 32%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal-dialog {
    /* max-width: 100%; */
    margin: 1.75rem auto;
}

@media screen and (max-width:480px) {
  .qtShareContacts {
    text-align: center;
}
}

@media only screen and (max-width: 600px) {
  .qtShareContacts {
    text-align: center;
}
}

@media (min-width: 576px)
{
  .qtShareContacts {
    text-align: center;
}

  .cadEmails{
    width:100%;
  }

.left_modal .modal-dialog {
    max-width: 100%;
}

.right_modal .modal-dialog {
    max-width: 100%;
}
}
.modal.left_modal .modal-content,
.modal.right_modal .modal-content {
  /*overflow-y: auto;
    overflow-x: hidden;*/
    height: 100vh !important;
}

.modal.left_modal .modal-body,
.modal.right_modal .modal-body {
  padding: 15px 15px 30px;
}

/*.modal.left_modal  {
    pointer-events: none;
    background: transparent;
}*/

.modal-backdrop {
    /* display: none; */
}

/*Left*/
.modal.left_modal.fade .modal-dialog{
  left: -50%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left_modal.fade.show .modal-dialog{
  left: 0;
  box-shadow: 0px 0px 19px rgba(0,0,0,.5);
}

/*Right*/
.modal.right_modal.fade .modal-dialog {
  right: -50%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}



.modal.right_modal.fade.show .modal-dialog {
  right: 0;
  box-shadow: 0px 0px 19px rgba(0,0,0,.5);
}

/* ----- MODAL STYLE ----- */
.modal-content {
 
  border: none;
}



.modal-header.left_modal, .modal-header.right_modal {

  padding: 10px 15px;
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

.modal_outer .modal-body {
    /*height:90%;*/
    overflow-y: auto;
    overflow-x: hidden;
    height: 91vh;
}

.qtLoginNew{
  text-decoration: none;
    font-size: 18px;
}

.qtLoginBtn{
  background-color: #116666;
    border: none;
    border-radius: none;
    color: #FFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.qtRegisterBtn{
  background-color: #116666;
  width: 100%;
    border: none;
    border-radius: none;
    color: #FFF;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.align-center{
  text-align: center;
}

.qtReLogin{
  text-transform: uppercase;
    font-weight: bold;
}

/** Testimonial Slider **/

.carousel-inner {
  height: 538px;
}

.carousel-control-prev {
  height: 50px;
  left: unset !important;
  position: fixed !important;
  top: -5px !important;
  right: 50%;
  color: gray !important;
}

.carousel-control-next {
  height: 50px;
  right: unset !important;
  position: fixed !important;
  top: -5px !important;
  left: 50%;
  color: gray !important;
}

.qtCountMain{
  height: 200px;
    background-color: #0d4969;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtCountMains{
  padding-top: 2em;
}

.qtCountMains h3{
  color: #FFF;
}

.qtCountsMain{
  height: 150px;
    background-color: #FFF;
    margin: 0 auto;
    width: 80%;
    margin-top: -6%;
    box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    margin-bottom: 2em;
    border-radius: 10px;
}

.allban{
  display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    border-right: 4px solid #0D4969;
}

.ban_featleft {
  font-size: 36px;
    color: rgb(13 73 105);
    padding-right: 5px;
}

.ban_featright {
  padding-left: 5px;
}

.ban_featright p {
  font-size: 16px;
  font-weight: 600;
}

.ban_featright span {
  display: block;
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 0;
}

.qtTrainer{
  text-align: left;
    margin-top: 2em;
    height: 350px;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtTrainerContents{
  padding: 10px;
    background: #FFF;
    border-radius: 10px;
    -webkit-box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    box-shadow: rgb(19 46 112 / 10%) 0 1rem 4rem 0;
    border-left: 4px solid #FF0000;
    text-align: center;
}

.qtTrainerContents img{
  width:100%;
  width: 70%;
    height: 100px;
}

.qtTrainerHeader{
  padding-bottom: 1.5em;
}

#callWhatsapp {
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  left: 10px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  text-indent: 100%;
  background: url('../src/images/whatsapp.png');
  border-radius: 50%;
  z-index: 99999;
  transition: all 0.2s ease-in-out;
}

#callPhone {
  text-decoration: none;
  position: fixed;
  bottom: 100px;
  left: 10px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  text-indent: 100%;
  background: url('../src/images/phonecall.png') no-repeat;
  border-radius: 50%;
  z-index: 99999;
  transition: all 0.2s ease-in-out;
  color: rgba(255,255,255,1);
  box-shadow: 0 5px 15px rgb(145 92 182 / 40%);
}

.qtTrueFooterBottom{
  width:100%;
  z-index: 1;
  background: #800000;
  padding:10px;
  position: fixed;
    bottom: 0;
    left: 0;
}

.qtTrueFooterBottom ul{
  display: inline;
    list-style: none;
    margin:0;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtTrueFooterBottom ul li{
  color: #FFF;
    font-weight: bold;
    display:inline-block;
    margin-left: 40px;
    cursor: pointer;
}

.qtTalkComponent{
  width:100%;
  background: url('../src/images/talkourexpert.jpg') no-repeat;
  height:150px;
  background-size: cover;
  margin-top: 2.5em;
}

.qtTalkContent{
  text-align: left;
    color: #FFF;
    font-weight: bold;
    font-size: 25px;
    height:150px;
    display: table-cell;
    vertical-align: middle;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtTalkLink{
  height: 150px;
    display: table-cell;
    vertical-align: middle;
}

.qtTalkLink a{
  text-decoration: none;
    background: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
}

.qtCourseHeader{
  background-color: #f3f8fb;
  padding-top: 2em;
    padding-bottom: 2em;
}

.qtCourseTitle{
  text-align: left;
}

.qtCourseIcons{
  text-align: left;
}

.qtCourseIcons ul{
  list-style: none;
    padding: 0;
}

.qtCourseIcons ul li{
  display: inline;
}

.qtCourseIcons ul li span{
  padding-left: 10px;
}

.qtCourseIcons ul li:last-child{
  margin-left: 2em;
}

.fa-star{
  color: #f5971b;
}

.qtMainContent{
  text-align: left;
}

.qtCourseButtons{
  text-align: left;
}

.qtCourseButtons .submit{
  background: #116666;
  border: none;
  color: #FFFFFF;
  /* padding: 10px; */
  padding-left: 20px;
  border-radius: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.qtCourseButtons .submit_download{
  background: #FFF;
    border: 2px solid #116666;
    color: #116666;
    padding-left: 20px;
  border-radius: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.qtCourseFuture{
  margin-top: 2em;
    margin-bottom: 2em;
    text-align: left;
}

.qtCourseFuture h1 {
  font-size: 25px;
}

.qtTitle h1{
  font-size: 25px;
}

.qtTitle h2{
  font-size: 25px;
}

.alignLeft{
  text-align: left;
}

.qtCourseContent{
  margin-top: 2em;
}

.qtCourseContents{
  border: 2px solid #fff4f4;
  padding: 16px;
}

.qtCouTitle h2{
  font-size: 18px;
}

.qtFutureTitle{
  margin-bottom: 2em;
}

.qtCertificate{
  margin-bottom: 2em;
  background-color: #f3f8fb;
  padding-top: 2em;
    padding-bottom: 2em;
}

.qtCertContent{
  font-size: 18px;
  font-weight: normal;
}

.qtCertsMainContent{
  margin-top: 2em;
}

.textAlignLeft{
  text-align: left;
}

.qtWho{
  margin-bottom: 2em;
}

.qtWhoContent{
  margin-top: 2em;
}

.qtSubTitle h2{
  font-size: 20px;
    text-align: left;
    margin-top: 1em;
}

.qtWhoContents ul{
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  margin-top: 1em;
  margin-bottom: 2em;
}

.qtWhoContents ul li{
  font-size: 23px;
}

.qtWhoContents ul li span{
 color:#0D4969;
}

.qtCourseImg img{
  width: 60%;
}

.qtSubContent p{
    text-align: left;
    font-size: 18px;
    margin-top: 1em;
    margin-bottom: 1em;
}

.qtApplyTitle h1{
  font-size: 25px;
}

.qtApplyTitle h3{
  font-size: 20px;
}

/* .qtCourseApply{
  padding: 10px;
    border: 3px solid #116666;
    border-radius: 10px;
    background: #FFFFFF;
} */

.qtCurriculam{
  padding-top: 2em;
    padding-bottom: 2em;
    background: #f3f8fb;
}

.qtCurriculam .qtCourseApply{
  padding: 10px;
    border: 3px solid #116666;
    border-radius: 10px;
    background: #FFFFFF;
    position: sticky;
    position: -webkit-sticky;
    top:135px;

}

.qtSuccessStory{
    padding-top: 2em;
    padding-bottom: 2em;
}

.qtStoryContents{
  margin:16px;
  background: #FFF;
    
    border-radius: 10px;
    box-shadow: 0 20px 20px 0 rgb(49 69 244 / 10%);
}

.qtStoryHeader img{
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.qtStContent{
  text-align: justify;
    padding: 16px;
}

.qtModalAppLeft{
  /* background: #0d4969; */
  text-align: center;
    padding-top: 25px;
}

.closeModal{
  background: #848989;
  border: none;
  color: #FFFFFF;
  /* padding: 10px; */
  padding-left: 20px;
  border-radius: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.qtCourseText{
  text-align: left;
}

.qtDesignation{
  margin: 1em;
}

.qtTimingContent ul{
  list-style: none;
  text-align: center;
  margin:0;
  background: #fff;
    padding: 3px;
    box-shadow: 0 3px 10px 0 rgb(0 32 119 / 15%) !important;
    border-radius: 25px;
}

.qtTimingBatchContent ul{
  list-style: none;
  text-align: center;
  margin:0;
  background: #fff;
    padding: 3px;
    box-shadow: 0 3px 10px 0 rgb(0 32 119 / 15%) !important;
    border-radius: 25px;
}

.qtTrainingTime{
  padding: 2em;
  margin: 0 auto;
  width: 75%;
}

.qtTimingContent ul li{
  display: inline-block;
    width: 50%;
    padding: 10px;
    
    border-radius: 20px;
}

.qtTimingBatchContent ul li{
  display: inline-block;
    width: 25%;
    padding: 10px;
    border-radius: 20px;
    font-weight: bold;
}

.qtTimingContent ul .timingActive
{background: rgb(2 100 198);
    border-radius: 20px;
    color: #FFF;}

.qtTimingContent ul li:hover,.qtTimingBatchContent ul li:hover{
  background: rgb(13 73 105);
  color: #FFF;
  cursor: pointer;
}

.qtTimingBatchContent table{
  border:1px solid #dceaf7;
  width: 100%;
}

.qtTimingBatchContent table tr td{
  border : 1px solid #dceaf7;
  padding:10px;
}

.qtTimingBatchContent table tr:nth-child(even) {
  background-color: #eee;
}
.qtTimingBatchContent table tr:nth-child(odd) {
  background-color: #fff;
} 

.qtDesContent ul{
  list-style: none;
    text-align: center;
    margin:0;
}

.qtDesContent ul li{
  display: inline-block;
    width: 150px;
    padding: 10px;
    background: rgb(189, 191, 193);
    margin-left: 10px;
    border-radius: 20px;
}

.qtDesContent ul li:hover{
  background: rgb(13 73 105);
  color: #FFF;
  cursor: pointer;
}

.qtDesSalarys{
  margin: 2em;
}

.qtDesSalary{
  margin: 0 auto;
  width: 50%;
  padding: 1em;
  /* border: 1px solid #cfd3d3; */
  -webkit-box-shadow: 1px 1px 15px -1px rgba(173,168,173,1);
-moz-box-shadow: 1px 1px 15px -1px rgba(173,168,173,1);
box-shadow: 1px 1px 15px -1px rgba(173,168,173,1);
border-radius: 10px;
}

.qtFutureIcon{
  text-align: center;
    font-size: 45px;
    color: rgb(12,149,132);
}


.qtWhyCubic{
  background: #f3f8fb;
  padding: 1em;
}

.qtWhyContent{
  background: #FFFFFF;
    border: 1px solid #e9e9e9;
    margin: 1em;
}

.qtWhyHeader{
  padding: 10px;
    border-bottom: 1px solid #e9e9e9;
    text-align: left;
    font-weight: bold;
    color: #0D4969;
    font-size: 18px;
}

.qtWhyContents ul li{
  text-align: left;
  padding-top: 10px;
}

.qtWhyContentsLoader{
   padding-left: 10px;
    padding-right: 10px;
}

.qtWhyContentsLoader ul{
      list-style: none;
    padding: 0;
}

.qtToolsContent{
  /* margin: 0 auto;
    width: 50%; */
    text-align: center;
    margin-top: 1em;
}

.qtToolsContent ul{
  list-style: none;
  padding:0;
}

.qtTools{
  margin: 2em;
}

.qtToolsContent ul li{
  margin-top: 10px;
  background: rgb(227 235 242);
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
}

.qtToolsCovered{
  width:100%;
  padding:10px;
  background-color: #FFF;
  box-shadow: 0 3px 10px 0 rgb(0 32 119 / 15%) !important;
  margin-top: 2em;
  border-radius: 10px;
}

.qtToolsIcon img{
  width: 100%;
}

.qtFAQ{
  margin: 2em;
}

.qtFAQContent{
  margin:2em;
}

.qtApplyCurriculam{
  margin: 2em;
    padding: 20px;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 10px solid #0080ff;
    box-shadow: 0 3px 10px 0 rgb(0 32 119 / 15%) !important;
}

.qtApplyCurriculam img{
  width: 100px;
}

.qtCourseButtonsFull .submit{
  background: #116666;
  border: none;
  color: #FFFFFF;
  /* padding: 10px; */
  padding-left: 20px;
  border-radius: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width:100%;
  margin-top: 5px;
}

.qtApplyHeader{
  margin:0 auto;
  width:80%;
}

.qtProject{
  margin:2em;
}

.qtContactWidget{
  position: fixed;
    top: 200px;
    right: -40px;
    z-index: 500;
}

.qtContactWidgets{
  background-color: #0d4969;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 10px 15px 15px;
  /* -webkit-transform: rotate(-90deg); */
  transform: rotate(-90deg);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.qtContactIcon{
  margin-right: 5px;
}

.qtContactIcon svg
{
  -webkit-animation-name: contactus_trin__1hs1e;
    animation-name: contactus_trin__1hs1e;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
    fill: #fff!important;
}

.qtShareContacts{
  text-align: right;
}

.qtShareContacts span{
  color:#0d4969;
}

.qtShareContacts span svg{
  fill: #0d4969;
}

.qtShareContacts ul{
  display: inline-block;
    list-style: none;
}

.qtShareContacts ul li{
  display: inline-block;
  font-weight: bold;
}

.qtShareIcons{
  width: 38px;
    height: 38px;
    margin: 0 0 0 9px;
    -webkit-box-shadow: 0 3px 6px 0 rgb(0 0 0 / 1%);
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 1%);
    background-color: #fff;
    border-radius: 20px;
    border: 0.6px solid #eff0f2;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    cursor: pointer;
}

.qtTimingBatch{
  width: 30%;
  margin: 2em auto;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgb(0 32 119 / 15%) !important;
}
.qtTimingBatch span{
  text-transform: uppercase;
  color: #0d4969;
  font-weight: bold;
  cursor: pointer;
}

.qtTimingBatch span i{
  margin-right: 10px;
}

.accordion__button{
  font-weight: bold;
}

.qtContactModal{
  position: fixed;
  top: 166px;
    /* right: -40px; */
    right:0px;
    z-index: 500;
    
}

.qtContactModals{
  background-color: #FFFFFF;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 10px 15px 15px;
    /* -webkit-transform: rotate(-90deg); */
    box-shadow: 0 3px 10px 0 rgb(0 32 119 / 15%) !important;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.qtContacts{
  color: #0D4969;
    font-weight: bold;
    font-size: 16px;
}

.qtContactClose{
  text-align: right;
  color: #0d4969;
}

.qtContactTitles{
  margin-top: 10px;
  margin-bottom: 10px;
}

.qtContactTitles span{
  margin-right: 10px;
}

.qtCtAvailable{
  margin-top: 8px;
}

.scrollTop{
  position: fixed;
    bottom: 10px;
    /* right: -40px; */
    right: 10px;
    z-index: 500;
}

.scrollTops{
  background: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}

.hrRule{
  margin-top: 16px;
  width: 100%;
  height: 1px;
  background-color: #c2c4c7;
  margin-bottom: 16px;
}

.googleLogin{
  margin-top: 16px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: 1px solid #c2c4c7;
  padding:5px;
}

.qtWhyIcon{
  font-size: 30px;
}

.qtWhyChooseHead{
  font-size: 20px;
    font-weight: bold;
    padding: 10px;
}

.qtWhyChooseContents{
  padding:10px
}

.qtWhyChooseMain{
  padding-top: 2em;
  height: 250px;
    /* line-height: 250px; */
    /* padding: 20px; */
    /* margin: 16px; */
    /* border: 1px solid; */
    /* border-right: 1px solid #b4b3b3; */
}

.qtWhyChooseMain:hover{
  background-color: #e8f7ff54;
}

.brLeftRight{
  border-right: 1px solid #b4b3b3; 
  border-left: 1px solid #b4b3b3; 
}

.hrLine{
  height:1px;
  width:100%;
  background-color: #b4b3b3;
}

.freePadding{
    padding-left: 0px !important;
    padding-right: 0px !important;

}

.qtreeAboutUs{
  margin-top: 1em;
}

.qtAboutMainContent{
  text-align: justify;
}

.qtMainHeads
{
  margin-bottom: 1em;
}

.qtHrSmallBorder{
  height: 5px;
  /* background-color: #0C9584; */
  width: 100%;
  border-bottom: 1px solid #c7c6c6;
}

.qtHrLineBorder{
  background-color: #0359af;
  height: 5px;
  width: 15%;

}

.textAlignLeft{
  text-align: left;
}

.textAlignRight{
  text-align: right;
}

.qtContactHeader{
  background: url('images/wave.svg');
  width: 100%;
  height: 450px;
  background-size: cover;
  text-align: left;
    color: #FFF;
    font-weight: bold;
    font-size: 2.5em;
    line-height: 350px;
}

.qtContactHeading{
  padding-top: 1em;
  padding-bottom: 1em;
}

.qtContactForms form{
  text-align: left;
}

.qtContactForms form label{
  font-weight: bold;
}

.text_align_left{
  text-align: left;
}

.profileIcon{
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: 14px;
  margin-bottom: 4px;
  cursor: pointer;
  /* position: absolute; */
  line-height: 35px;
}

.profileIcon span{
  font-weight: bold;
  color:#000 !important;
  user-select: none;
}

.navigation-menu .learning-menus .content{
  background-color:#ffffff;
  /* padding:25px 20px; */
  border-top:4px solid #b8b8b8;
  width:100%;
  padding-top: 10px;
}

.cadLearnMenu .row{
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: center;
}

.learningMenus{
  display: block !important;
  padding-bottom: 10px;
    padding-top: 10px;
}

.learningMenus li{
  display: block;
}

.profileMenus li a{
  color:#0D4969 !important;
  text-align: left;
  padding:0px 10px 5px 10px !important;
}

.profileMenus li a:hover{
  background-color: #03383f;
  color:#FFF !important;
}

.profileMenus{
    display: block !important;

    /* display: none;
    margin-left: -10px;
    position: absolute;
    padding: 0;
    text-align: left;
    background: white;
   
    margin-right: 10px;
    margin-top: 4px;
    -webkit-box-shadow: 3px 3px 17px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 17px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 17px 0px rgba(0,0,0,0.75); */
}

/* .profileMenus ul li{
  margin: 0 !important;
  display: block !important;
  color: #000 !important;
}

.profileIcon ul li a{
  color: #000 !important;
    margin: 0;
    padding: 0;
    text-align: left;
    padding-left: 10px;
    -webkit-user-select: none;
    user-select: none;
    padding-right: 10px;
}

.profileIcon:hover>.profileIcon ul{
  display: block;
}

.profileIcon ul li:hover{
  background-color: #a3a3a3;
} */

.profileContent{
  border: 1px solid #c4bfbf;
    width: 100%;
    height: auto;
    
    margin: 1.5em;
    border-radius: 10px;
}

.profileHomeTop{
  background: #0D4969;
    padding: 10px;
    color: white;
    border-top-left-radius: 10px;
    font-weight: bold;
}

.profileMenu ul{
  list-style: none;
    text-align: left;
    padding: 0;
    margin-top: 1em;
}

.profileMenu ul li{
  padding: 10px;
}

.profileMenu ul li:hover{
  background: #EDF2F7;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.profileLeft{
  border-right: 1px solid #c4bfbf;
  padding-right: 0px !important;
}

.profileRight{
  padding-left: 0px !important;
}

.profileRightTop{
  font-weight: bold;
    padding: 10px;
    padding-left: 0;
    border-bottom: 1px solid #c4bfbf;
}

.profileForms{
    width: 60%;
    margin: 0 auto;
    text-align: left;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .myCoursesHeader{
    width: 100%;
    height: 100px;
    background: #0D4969;
    text-align: left;
    color: #FFF;
    font-weight: bold;
    font-size: 25px;
    line-height: 90px;
  }

  .myCourseHeader img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100px;
  }

  .myCourseList{
    margin: 1em;
  }

  .myCourseLists{
    border: 1px solid #c4bfbf;
    border-radius: 10px;
    cursor: pointer;
    height: 160px;
  }

  .myCourseLists:hover{
    background-color: #e8f7ff54;
  }

  .myCourseDetails h4{
    font-size: 18px;
    text-align: left;
    padding: 10px;
  }

  .programsFee{
    text-align: left;
    font-weight: bold;
    color: #000;
    font-size: 18px;
  }

  .myCart{
    background-color: #FFFFFF;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    color: red !important;
    font-weight: bold;
    line-height: 25px;

  }

  .cadCartContainer{
    margin: 2em;
    text-align: left;
  }

  .cadCartItem{
    height:60px;
  }

  .cadCartHeading{
    margin-bottom: 2em;
  }

  .cadCartHeading h3{
    font-weight: bold;
    font-size: 40px;
  }

  .cadCartCount{
    font-weight: bold;
  }

  .cadCartOption ul
  {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .cadCartOption ul li{
    cursor: pointer;
    color: #367A72;
    font-weight: bold;
  }

  .cadCartValue{
    color: #0D4969;
    font-weight: bold;
    font-size: 20px;
    line-height: 60px;
    text-align: right;
  }

  .cadCartDetails h2{
    padding: 0;
    margin: 0;
    /* line-height: 50px; */
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
  }

  .cadCartLValue{
    text-align: right;
    font-weight: bold;
    color: black;
  }

  .cadCheckOutBtn{
    height: 45px;
    width: 100%;
    background: #367A72;
    text-align: center;
    color: white;
    font-weight: bold;
    line-height: 45px;
    cursor:pointer;
    margin-top: 12px;
  }

  .cadAddedCart{
    font-weight: bold;
    font-size: 35px;
    text-align: center;
    margin-top: 2em;
  }

  .cadCart{
    width: 100%;
    border: none;
    background: #367A72;
    margin: 1em;
    padding: 10px;
    color: #FFFFFF;
    font-weight: bold;
    display: block;
    text-align: center;
    text-decoration: none;
  }

  .cadCartContinue{
    width: 100%;
    border: none;
    background: #7a363f;
    margin: 1em;
    padding: 10px;
    color: #FFFFFF;
    font-weight: bold;
  }

  /**
    Loading Background Spinner
  **/
  /* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
  Loading Spinner End
**/

.shareTop{
  text-align: center;
}

.shareMain{
  margin-top: 2em;
  margin-bottom: 2em;
}

.shareLink{
  width: 100%;
  padding:8px;
  background-color: #dfe1e2;
  border-radius: 22px;
  color: #000000;
}

.copyShareLink{
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  background-color: #dfe1e2;
  border-radius: 30px;
  cursor: pointer;
}

.copyShareLink svg{
  width:20px;
}

.socialShareIcon ul{
  list-style-type:none;
  padding: 0;
  text-align: center;
  margin-top: 1em;
}

.socialShareIcon ul li{
  display: inline-block;
}

.socialShareIcon ul li:not(:first-child)
{
  margin-left: 10px;
}

.copyText{
  text-align: center;
    color: #c7517d;
    font-weight: bold;
    /* margin-bottom: 10px; */
    padding: 10px;
}

.courseMenu .sMenu{
  display: none;
  width: 300px;
}

.courseMenu:hover>.sMenu{
    display: block;
}

.MuiInputBase-root{
  /* border-radius: 50px !important; */
  border:0px !important;
}

.sMenu{
  background: #ffffff;
  position: absolute;
  padding: 10px;
  display: none;
  color:#000000;
  /* border:1px solid #000000; */
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  transition: visibility 0s, opacity 0.5s linear;
  z-index: 1;
}

.sMenuRight{
  /* line-height: 18px;
    padding: 0; */
    text-align: left;
    color: #0D4969 !important;
    font-weight: bold;
}

.sMenuLeft img{
  border: 1px solid #dbc7cb;
}

.react-simple-star-rating svg{
  width:20px;
  height:20px;
}

.sMenu img{
  border :1px solid #000000;
  margin-right: 10px;
}

.sMenu li{
  display: block !important;
  text-align: left;
  margin: 0 !important;
  border-bottom: 1px solid #fffdfd;
  font-weight: 600;
}

.sMenu li a{
  color: #000000 !important;
  cursor: pointer !important;
  padding: 5px;
  display: block;
}

.sMenu li a:hover{
  visibility: visible;
  /* background-color: #FFF;
  color: #0D4969 !important; */
}

.cadLearnMenu{
  margin-bottom: 10px;
}

.qtCourseOverlay{
  width: 525px;
    height: 315px;
    background: #00000061;
    /* z-index: 1; */
    position: absolute;
}

/**
Status
**/

.titleSpan{
  width: 50px;
  height: 50px;
  background-color: #bfced9;
  text-align: center;
  font-size: 28px;
  line-height: 50px;
}

.pending{
  background: #e6e408;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
    border-radius: 10px;
    float: left;
}

.going-on{
  background: #086c05;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
    border-radius: 10px;
    float: left;
}

.completed{
  background: #918101;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: white;
    border-radius: 10px;
    float: left;
}

.viewMaterial{
  padding:10px
}

.float-right{
  float:right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.viewMaterial img{
  height: 25px;
    width: 25px;
}

.matDescription{
  font-weight: 600;
    font-size: 19px;
    line-height: 40px;
}

.viewMaterial ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.viewMaterial ul li{
  border: 1px solid #dedcdc;
    padding: 10px;
}

.download{
  cursor: pointer;
}

.currencyConversion{
  font-size: 12px;
    background: #03383f;
    color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
}

.qtCertsSample{
  cursor: pointer;
}

/**
Animation
**/

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #D7D4E4;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #FFF ;
}
.cube:nth-child(2) {
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}


@keyframes move_wave {
  0% {
      transform: translateX(0) translateZ(0) scaleY(1)
  }
  50% {
      transform: translateX(-25%) translateZ(0) scaleY(0.55)
  }
  100% {
      transform: translateX(-50%) translateZ(0) scaleY(1)
  }
}
.waveWrapper {
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.waveWrapperInner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 50%;
  bottom: -1px;
  background-image: linear-gradient(to top, #125683 20%, #27273c 80%);
}
.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 50%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}
.waveTop {
  background-size: 50% 100px;
}
.waveAnimation .waveTop {
animation: move-wave 3s;
 -webkit-animation: move-wave 3s;
 -webkit-animation-delay: 1s;
 animation-delay: 1s;
}
.waveMiddle {
  background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}
.waveBottom {
  background-size: 50% 100px;
}
.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

.qtAddress{
  margin-top: -200px;
}

.qtAddressMap{
  margin-top: 150px;
}

.qtContactContainer{
  background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.qtTestimonial{
  background: white;
    padding: 10px;
    -webkit-box-shadow: 0px 1px 13px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 13px -7px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 13px -7px rgba(0,0,0,0.75);
    border-radius: 15px;
    margin: 10px;
}


.qtTestContents{
  font-size: 20px;
}

.qtTestContents p{
  font-size: 15px;
    font-style: italic;
    color: #6c757d;
}

.qtTestProfile img{
  display: inline;
}

.qtProfileName
{
  font-size: 20px;
  font-weight: bold;
}

.qtProfileCourse{
  font-size: 15px;
}

.qtTestHeading{
  margin-bottom: 4em;
}

.qtTestimonialContents span i{
  font-size: 25px;
    color: #135de9;
}

.slick-dots{
  bottom: -15px !important;
}

.slick-prev{
 
    /* width: 30px;
    height: 30px;
    background: #c7cdcd;
    border-radius: 50px; */
    left: -50px;
}

.slick-next{
 
  /* width: 30px;
  height: 30px;
  background: #c7cdcd;
  border-radius: 50px; 
  right: -50px;*/
}

.slick-prev:hover,.slick-next:hover{
  /* width: 30px;
  height: 30px;
  background: #c7cdcd;
  border-radius: 10px; */
}

.slick-next:before, .slick-prev:before{
  color:#0D4969;
  font-size: 30px;
}

.batchLink{
  color: #0447c0;
    font-weight: bold;
    cursor: pointer;
}

.profileSubHeading{
  font-weight: bold;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
}

.formSuccessResponse{
  width: 100%;
    background: #c6f5c0;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 10px;
}

.formErrorResponse{
  width: 100%;
    background: #f5c0c0;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 10px;
}

.oPagesContainer{
  background: white;
    padding: 10px;
    margin-top: -180px;
    border-radius: 10px;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

.qtDesContent ul li p,.qtToolsContent ul li p
{
  margin:0;
}

.qtTitleAccordian ul li
{
	list-style-type: disc;
}

.myCourseList a{
  text-decoration: none;
    text-align: center;
    color: black;
}

.myCourseDetails h4{
  text-align: center;
}

.footer .App{
  min-height: auto !important;
}

.qtSocial a{
  text-decoration: none;
}

.qtTrueFooterBottom li a{
  text-decoration: none;
    color: white;
}

.profileHeading{
  font-weight: bold;
    font-size: 18px;
}

.profileContents{
   padding-top: 10px;
   padding-bottom: 10px;
}

.cadEmail{
  
  margin-top: 2em;

}

.cadEmails{
  margin: auto;
    background: #EDF2F7;;
    width: 65%;
    padding:1.5em;
}

.cadEmailsMain{
  background: white;
  padding:10px;
}

.cadEmailStatus{
  font-weight: bold;
    font-size: 20px;
    margin:1.5em;
}

.cadEmailHeading{
  margin-top: 1.5em;
      font-weight: bold;
      font-size: 18px;
}

.cadEmailContents{
  color: #9f9b94;
    margin: 1.5em;
}

.plinkActive{
  background: #EDF2F7;
    font-weight: bold;
}

.swal2-container{
  z-index: 9999999;
}

.grecaptcha-badge { visibility: hidden; }

.sPadding{
  padding: 10px;
}

.cartCadTax,.cartGrandTax{
  font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cartContainer{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.vMaterial{
  display: flex;
    flex: auto;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}
.cadHAddress{
font-weight: bold;
    color: #d40f0f;
}

.border-none{
  border:0;
}

.font-bold{
  font-weight: bold;
}

.mb-10{
  margin-bottom: 10px;
}

.mt-5{
  margin-top:2px;
}

.flex{
  display: flex;
  justify-content: space-between;
}

.cursor-pointer{
  cursor: pointer;
}

.clearVoucher{
  color: red;
  font-weight: bold;
}

.footerLogos{
  display: flex;
}

.footerLogo{
  background-color: white;
    /* height: 70px; */
    border-radius: 10px;
    display: flow;
    margin: auto;
    width: 250px;
}

.toolText{
  font-size: 18px;
  background: #0d4969;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: white;
}

.text-decoration-none{
  text-decoration: none;
}

.text-color-black{
  color: black;
}

.hover-light-teal:hover>.qtExpContent{
  background-color: #d6f7eb;
}














